// components
import FormValidation from '@/js/components/forms/formValidation';
import GoogleMaps from '@/js/components/google_maps/map';
import Swiper, {
  Navigation, Pagination, EffectFade, Lazy, Autoplay, Thumbs,
} from 'swiper/core';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay, Thumbs]);

class Contact {
  constructor() {
    this.dom = {
      mapEl: document.getElementById('map'),
      formContact: document.getElementById('form-contact'),
      alertScroll: document.querySelector('.js-alert-scroll:not(.d-none)'),
      swiperHospitals: document.getElementById('hospital-swiper'),
    };

    this.data = {
      swiperConfig: {
        loop: true,
        speed: 1500,
        slidesPerView: 4,
        spaceBetween: 24,
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          530: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        },
        allowTouchMove: false,
        autoplay: {
          delay: 3000,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: {
          checkInView: true,
          loadPrevNext: true,
        },
        effect: 'slide',
      },
    };

    this.props = {
      mapMarkers: null,
      mapOptions: null,
      infoWindowContent: null,
    };

    this.components = {
      googleMaps: null,
      contactFormValidation: null,
      swiperInstance: null,
    };

    this.events = {
      onMapLoaded: this.onMapLoaded.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.mapEl) {
      this.initMaps();
    }

    if (this.dom.formContact) {
      this.components.contactFormValidation = new FormValidation(this.dom.formContact);
    }

    if (this.dom.alertScroll) {
      this.dom.alertScroll.scrollIntoView({ behavior: 'smooth' });
    }

    if (this.dom.swiperHospitals) {
      const hospitalSwiper = new Swiper(this.dom.swiperHospitals, this.data.swiperConfig);
      const swiperContainer = this.dom.swiperHospitals;
      // Add mouseleave event listener
      swiperContainer.addEventListener('mouseenter', () => {
        hospitalSwiper.autoplay.stop();
      });
      swiperContainer.addEventListener('mouseleave', () => {
        hospitalSwiper.autoplay.start();
      });
    }
  }

  initMaps() {
    // parse props
    this.props.mapOptions = JSON.parse(this.dom.mapEl.dataset.options);
    this.props.mapMarkers = JSON.parse(this.dom.mapEl.dataset.markers);

    // listen to map loaded
    this.dom.mapEl.addEventListener('loaded', this.events.onMapLoaded);

    // create map instance
    this.components.googleMaps = new GoogleMaps(this.dom.mapEl, this.props.mapOptions);
  }

  onMapLoaded() {
    // Iterate over elements with the class b-hospital-info
    const hospitalElements = document.querySelectorAll('.b-hospital-info');

    hospitalElements.forEach((element) => {
      const latitude = element.getAttribute('data-latitude');
      const longitude = element.getAttribute('data-longitude');
      const name = element.getAttribute('data-name');

      // Check if latitude and longitude are available and valid
      // eslint-disable-next-line no-restricted-globals
      if (latitude && longitude && !isNaN(parseFloat(latitude)) && !isNaN(parseFloat(longitude))) {
        // Create marker data using latitude and longitude
        const markerData = {
          position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
          // Add other marker properties as needed
        };
        const infoWindowContent = `<div><strong>${name}</strong></div>`;
        // Add marker to the Google Map
        this.components.googleMaps.addMarker(markerData, infoWindowContent);
      }
    });
  }
}

if (document.getElementById('page-contact')) {
  // eslint-disable-next-line no-new
  new Contact();
}
