// eslint-disable-next-line import/no-extraneous-dependencies
import Fuse from 'fuse.js';
import { groupBy } from 'lodash-es';
import { highlight } from '@/js/utils/fuse';

class HelpCenterOverview {
  constructor() {
    this.dom = {
      searchForm: document.getElementById('faq-search-form'),
      searchInput: document.getElementById('search-help-center'),
      listSearchResults: document.getElementById('list-search-results'),
    };

    this.data = {
      fuse: null,
      // https://www.fusejs.io/api/options.html#options
      fuseOptions: {
        isCaseSensitive: false,
        shouldSort: true,
        minMatchCharLength: 3,
        threshold: 0.3,
        includeMatches: true,
        keys: ['title'],
        // includeScore: false,
        // findAllMatches: false,
        // location: 0,
        // distance: 100,
        // useExtendedSearch: false,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        // fieldNormWeight: 1,
      },
      fuseMaxResults: 5,
      fuseResults: [],
      questions: [
        {
          title: 'Voorbeeld vraag 1',
          url: 'http://localhost/boilerplate-revamp/nl/faq/111/rubriek-3/voorbeeld-vraag-1',
          category: 'Rubriek 3',
        },
        {
          title: 'Voorbeeld vraag 8',
          url: 'http://localhost/boilerplate-revamp/nl/faq/113/rubriek-2/voorbeeld-vraag-8',
          category: 'Rubriek 2',
        },
        {
          title: 'Voorbeeld vraag 9',
          url: 'http://localhost/boilerplate-revamp/nl/faq/112/rubriek-1/voorbeeld-vraag-9',
          category: 'Rubriek 1',
        },
        {
          title: 'Voorbeeld vraag 2',
          url: 'http://localhost/boilerplate-revamp/nl/faq/114/rubriek-3/voorbeeld-vraag-2',
          category: 'Rubriek 3',
        },
        {
          title: 'Voorbeeld vraag 7',
          url: 'http://localhost/boilerplate-revamp/nl/faq/116/rubriek-2/voorbeeld-vraag-7',
          category: 'Rubriek 2',
        },
        {
          title: 'Voorbeeld vraag 6',
          url: 'http://localhost/boilerplate-revamp/nl/faq/117/rubriek-2/voorbeeld-vraag-6',
          category: 'Rubriek 2',
        },
        {
          title: 'Voorbeeld vraag 4',
          url: 'http://localhost/boilerplate-revamp/nl/faq/119/rubriek-2/voorbeeld-vraag-4',
          category: 'Rubriek 2',
        },
        {
          title: 'Voorbeeld vraag 5',
          url: 'http://localhost/boilerplate-revamp/nl/faq/1210/rubriek-2/voorbeeld-vraag-5',
          category: 'Rubriek 2',
        },
        {
          title: 'Testvraag 10',
          url: 'http://localhost/boilerplate-revamp/nl/faq/1211/rubriek-2/testvraag-10',
          category: 'Rubriek 2',
        },
        {
          title: 'dit is een NL',
          url: 'http://localhost/boilerplate-revamp/nl/faq/1213/rubriek-1/dit-is-een-nl',
          category: 'Rubriek 1',
        },
      ],
    };

    this.mount();
  }

  mount() {
    if (this.dom.searchInput) {
      this.dom.searchInput.addEventListener('input', () => this.onSearchInput());
      this.dom.searchInput.addEventListener('focus', () => this.onSearchFocus());
      this.dom.searchInput.addEventListener('blur', () => this.onSearchBlur());
      this.initFuse();
    }

    if (this.dom.searchForm) {
      this.dom.searchForm.addEventListener('submit', (event) => this.onSearchSubmit(event));
    }
  }

  onSearchInput() {
    this.data.fuseResults = this.getAutocomplete(this.dom.searchInput.value);
    const groupedResults = groupBy(this.data.fuseResults, (result) => result.item.category);
    this.dom.listSearchResults.innerHTML = '';
    this.dom.listSearchResults.classList.toggle('show', this.data.fuseResults.length > 0);
    this.dom.searchInput.classList.toggle('has-matches', this.data.fuseResults.length > 0);

    Object.entries(groupedResults).forEach(([category, questions]) => {
      const liHeader = document.createElement('li');
      liHeader.innerHTML = `<div class="dropdown-header">${category}</div>`;
      this.dom.listSearchResults.appendChild(liHeader);

      questions.forEach((question) => {
        const liItem = document.createElement('li');
        liItem.innerHTML = `<a class="dropdown-item" href="${question.item.url}">${highlight(question.item.title, question.matches, ['title'])}</a>`;
        this.dom.listSearchResults.appendChild(liItem);
      });
    });
  }

  onSearchFocus() {
    if (this.data.fuseResults.length > 0) {
      this.dom.listSearchResults.classList.add('show');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onSearchBlur() {
    // this.dom.listSearchResults.classList.remove('show');
  }

  onSearchSubmit(event) {
    event.preventDefault();

    if (this.data.fuseResults.length > 0) {
      window.location.href = this.data.fuseResults[0].item.url;
    }
  }

  initFuse() {
    this.data.fuse = new Fuse(this.data.questions, this.data.fuseOptions);
  }

  getAutocomplete(searchValue) {
    return this.data.fuse.search(searchValue).slice(0, this.data.fuseMaxResults);
  }
}

if (document.getElementById('page-help-center-overview')) {
  // eslint-disable-next-line no-new
  new HelpCenterOverview();
}
