const offcanvas = document.getElementById('dashboard-nav');
const offcanvasToggle = document.getElementById('dashboard-nav-toggle');
const offcanvasClose = document.getElementById('dashboard-nav-close');

if (offcanvas) {
  offcanvasToggle.addEventListener('click', () => {
    offcanvas.classList.toggle('show-offcanvas');
  });

  offcanvasClose.addEventListener('click', () => {
    offcanvas.classList.toggle('show-offcanvas');
  });
}

const buttons = document.querySelectorAll('.c-dashboard-table-action');
const downloads = document.querySelectorAll('.c-dashboard-table-downloads');

if (buttons.length > 0 && downloads.length > 0) {
  buttons.forEach((button, index) => {
    button.addEventListener('click', () => {
      downloads.forEach((download, i) => {
        if (i !== index) {
          download.classList.remove('download-toggled');
        }
      });
      const download = button.closest('.c-dashboard-table-downloads');
      if (download) {
        download.classList.toggle('download-toggled');
      }
    });
  });
}
