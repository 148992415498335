const progressBar = document.getElementById('reading-progress');
const blogContainer = document.getElementById('blog-container');

if (progressBar && blogContainer) {
  // eslint-disable-next-line func-names
  window.onscroll = function () {
    // eslint-disable-next-line no-use-before-define
    readingProgress();
  };

  // eslint-disable-next-line no-inner-declarations
  function readingProgress() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    // eslint-disable-next-line no-shadow
    const blogContainer = document.getElementById('blog-container');
    // eslint-disable-next-line no-shadow
    const progressBar = document.getElementById('reading-progress');

    const contentHeight = blogContainer.offsetHeight;
    const contentOffsetTop = blogContainer.offsetTop;

    // eslint-disable-next-line no-unused-vars
    const scrolled = (winScroll / height) * 100;
    let ScrollBarWidth;
    const diff = winScroll - contentOffsetTop;
    if (diff <= 0) {
      ScrollBarWidth = 0;
    } else if ((winScroll + 100) <= (contentOffsetTop + contentHeight)) {
      ScrollBarWidth = (diff / contentHeight) * 100;
    } else {
      ScrollBarWidth = 100;
    }
    progressBar.style.width = `${ScrollBarWidth}%`;
  }
}
