import FormValidation from '@/js/components/forms/formValidation';
import GoogleMaps from '@/js/components/google_maps/map';

class EventsDetail {
  constructor() {
    this.dom = {
      mapEl: document.getElementById('event-map'),
      formEvent: document.getElementById('form-event'),
    };

    this.props = {
      mapMarkers: null,
      mapOptions: null,
      infoWindowContent: null,
    };

    this.components = {
      googleMaps: null,
      eventFormValidation: null,
    };

    this.events = {
      onMapLoaded: this.onMapLoaded.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.mapEl) {
      this.initMaps();
    }

    if (this.dom.formEvent) {
      this.components.eventFormValidation = new FormValidation(this.dom.formEvent);
    }
  }

  initMaps() {
    // parse props
    this.props.mapOptions = JSON.parse(this.dom.mapEl.dataset.options);
    this.props.mapMarkers = JSON.parse(this.dom.mapEl.dataset.markers);

    // listen to map loaded
    this.dom.mapEl.addEventListener('loaded', this.events.onMapLoaded);

    // create map instance
    this.components.googleMaps = new GoogleMaps(this.dom.mapEl, this.props.mapOptions);
  }

  onMapLoaded() {
    // add markers
    this.props.mapMarkers.forEach((marker) => {
      this.components.googleMaps.addMarker(marker);
    });
  }
}

if (document.getElementById('page-events-detail')) {
  // eslint-disable-next-line no-new
  new EventsDetail();
}
