class EventsPast {
  constructor() {
    this.dom = {
      blog: document.getElementById('blog-container'),
      tableOfContents: document.getElementById('blog-content-table'),
      blogHeaderWrapper: document.getElementById('blog-content-wrapper'),
    };

    this.mount();
  }

  mount() {
    if (this.dom.blog && this.dom.tableOfContents) {
      this.addContentList();
    }
  }

  addContentList() {
    // Get all the headings (h1, h2, h3, h4, h5) inside the blog container
    const headings = this.dom.blog.querySelectorAll('h1, h2, h3, h4, h5');

    if (headings.length === 0) {
      // If no headings found, hide the table of contents
      if (this.dom.blogHeaderWrapper) {
        this.dom.blogHeaderWrapper.style.setProperty('display', 'none', 'important');
      }
      return;
    }

    headings.forEach((heading) => {
      // Assign an id to the heading if it doesn't have one
      if (!heading.id) {
        // eslint-disable-next-line no-param-reassign
        heading.id = `heading-${Math.random().toString(36).substring(7)}`;
      }
      // Create an anchor element for the link
      const link = document.createElement('a');
      link.href = `#${heading.id}`; // Use the heading's id as the link target
      // Create a list item for the link and append it to the existing table of contents
      const listItem = document.createElement('li');
      listItem.appendChild(link);
      // Copy the heading's text into the link
      link.textContent = heading.textContent;
      // Append the list item to the existing table of contents
      this.dom.tableOfContents.appendChild(listItem);
    });
  }
}

if (document.getElementById('page-events-past') || document.getElementById('page-news-detail')) {
  // eslint-disable-next-line no-new
  new EventsPast();
}
