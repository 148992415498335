export default class Filter {
  constructor(filterEl) {
    this.dom = {
      filterEl,
      items: [...filterEl.querySelectorAll('.dropdown-item')],
      button: filterEl.querySelector('.btn-filter'),
      badge: filterEl.querySelector('.badge'),
    };

    this.props = {
      multiselectable: !!this.dom.filterEl.dataset.multiselect,
      originalText: this.dom.button.innerText,
    };

    this.events = {
      onFilterItemClick: this.onFilterItemClick.bind(this),
    };

    this.mount();
  }

  mount() {
    this.dom.items.forEach((item) => {
      item.addEventListener('click', this.events.onFilterItemClick);
    });

    this.updateActiveState();
  }

  /**
   * @param e {Event}
   */
  onFilterItemClick(e) {
    e.preventDefault();

    const { target } = e;

    // Change the active state on the item
    target.classList.toggle('active');

    const siblings = [...target.parentNode.parentNode.children]
      .map((el) => el.firstChild)
      .filter((el) => el !== target);

    if (!this.props.multiselectable) {
      // remove active state from siblings
      siblings.forEach((el) => el.classList.remove('active'));
    }

    this.updateActiveState();

    this.dom.filterEl.dispatchEvent(new Event('change'));
  }

  updateActiveState() {
    const count = this.dom.filterEl.querySelectorAll('.active').length;

    // When a badge is present display the count
    if (this.dom.badge) {
      this.dom.badge.innerHTML = String(count);
    }

    if (!this.props.multiselectable) {
      const activeItem = this.dom.items.find((item) => item.classList.contains('active'));

      if (activeItem) {
        this.dom.button.innerText = activeItem.innerText;
      } else {
        this.dom.button.innerText = this.props.originalText;
      }
    }

    // Add the active state to the filter
    if (count > 0) {
      this.dom.filterEl.classList.add('active');
      return;
    }

    this.dom.filterEl.classList.remove('active');
  }
}
