import ActionHandler from '@/js/components/forms/action_handler';
import Filter from '@/js/components/forms/filter';

export default class FilterForm extends ActionHandler {
  constructor(formEl) {
    super();

    this.dom = {
      formEl,
    };

    this.components = {
      filters: [...formEl.querySelectorAll('.filter-item')].map((field) => new Filter(field)),
    };

    this.props = {
      url: formEl.getAttribute('action') || '',
      type: null,
    };

    this.events = {
      onFormSubmit: this.onFormSubmit.bind(this),
    };

    this.mount();
  }

  mount() {
    this.dom.formEl.addEventListener('submit', this.events.onFormSubmit);

    this.components.filters.forEach((filter) => {
      filter.dom.filterEl.addEventListener('change', this.events.onFormSubmit);
    });
  }

  /**
   * @param e {Event}
   */
  onFormSubmit(e) {
    e.preventDefault();

    // set fields
    this.dom.fields = [...this.dom.formEl.querySelectorAll('.dropdown-menu .active')];
    // Initialize form data
    const formData = new FormData(this.dom.formEl);

    this.components.filters.forEach((filter) => {
      filter.dom.items
        .filter((item) => item.classList.contains('active'))
        .forEach((item) => {
          const { name, value } = item.dataset;

          if (!name || !value) {
            return;
          }

          if (filter.props.multiselectable) {
            // Add multi filter
            formData.append(`Filters[${name}][]`, value);
          } else {
            // Add single filter
            formData.append(`Filters[${name}]`, value);
          }
        });
    });

    this.send({ url: this.props.url, formData, action: 'filter' });
  }
}
