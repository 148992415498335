import FormValidation from '@/js/components/forms/formValidation';

class Account {
  constructor() {
    this.dom = {
      formLogin: document.getElementById('form-account-login'),
      formRegistration: document.getElementById('form-account-registration'),
      formPasswordForget: document.getElementById('form-account-password-forget'),
      formPasswordReset: document.getElementById('form-account-password-reset'),
      formChangePassword: document.getElementById('form-account-change-password'),
      updateAccount: document.getElementById('form-account-update'),
    };

    this.components = {
      validation: null,
    };

    this.mount();
  }

  mount() {
    // Find the existing form on the page
    Object.values(this.dom).find((element) => {
      // Check if the element exists and is a form
      if (!(element instanceof HTMLFormElement)) {
        return false;
      }

      // Initialize the form
      this.components.validation = new FormValidation(element);
      return true;
    });
  }
}

if (document.getElementById('page-account')) {
  // eslint-disable-next-line no-new
  new Account();
}
