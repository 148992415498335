/**
 * on form submit
 * @param e {Event}
 */
const dom = {
  postForms: document.querySelectorAll('form[method="post"]'),
};

const data = {
  spamCheck: {
    name: 'spamcheck',
    // eslint-disable-next-line no-undef
    value: String(SPAM_CHECK),
  },
};

/**
 * on form submit
 * @param e {Event}
 */
function onFormSubmit(e) {
  const form = e.target;
  const existingInput = form.querySelector('input[name="spamcheck"]');

  if (existingInput) {
    // spam check already in place
    form.removeEventListener('submit', onFormSubmit);
  } else {
    // add spam check
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = data.spamCheck.name;
    input.value = data.spamCheck.value;
    form.appendChild(input);
  }
}

// on post form submit
dom.postForms.forEach((form) => {
  form.addEventListener('submit', onFormSubmit, true);
});
