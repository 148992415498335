const navbar = document.querySelector('.l-site-nav');
const searchBtn = document.querySelector('.btn-search');
const navChecker = document.querySelector('.nav-bg');
const searchIcon = document.querySelector('.search-icon');
const searchClose = document.querySelector('.search-close');
const searchInput = document.getElementById('search-input');

let isActive = false; // Variable to track the state

if (navChecker && navbar) {
  navbar.classList.add('active-bg');
}

if (navbar) {
  navbar.addEventListener('mouseover', () => {
    if (!isActive) {
      navbar.classList.add('hover-bg');
    }
  });

  navbar.addEventListener('mouseout', () => {
    if (!isActive) {
      navbar.classList.remove('hover-bg');
    }
  });
}

if (navbar && searchBtn) {
  searchBtn.addEventListener('click', () => {
    if (isActive) {
      navbar.classList.remove('hover-bg');
      searchIcon.classList.remove('d-none');
      searchClose.classList.add('d-none');
    } else {
      navbar.classList.add('hover-bg');
      searchIcon.classList.add('d-none');
      searchClose.classList.remove('d-none');
    }

    isActive = !isActive; // Toggle the state
  });
}

if (searchBtn) {
  searchBtn.addEventListener('click', () => {
    searchInput.focus();
  });
}
