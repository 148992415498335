import FormValidation from '@/js/components/forms/formValidation';
import Swiper, {
  Navigation, Pagination, EffectFade, Lazy, Autoplay, Thumbs,
} from 'swiper/core';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay, Thumbs]);

class Home {
  constructor() {
    this.dom = {
      swiperIntro: document.getElementById('swiper-intro'),
      swiperSponsors: document.getElementById('sponsor-swiper'),
      swiperSponsorsThumbs: document.getElementById('sponsor-swiper-thumbs'),
      formSubscriber: document.getElementById('subscriber-form'),
    };

    this.data = {
      swiperConfig: {
        loop: true,
        speed: 1500,
        autoplay: {
          delay: 3500,
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: {
          checkInView: true,
          loadPrevNext: true,
        },
        effect: 'fade',
      },
      swiperThumbOptions: {
        loop: false,
        allowTouchMove: false,
        effect: 'slide',
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      },
    };

    this.components = {
      swiperInstance: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.swiperIntro) {
      this.components.swiperInstance = new Swiper(this.dom.swiperIntro, this.data.swiperConfig);
    }
    if (this.dom.swiperSponsors && this.dom.swiperSponsorsThumbs) {
      this.data.swiperThumbOptions.breakpoints = {
        768: {
          slidesPerView: 3, // On md (768px and up), show 2 slides per view
          spaceBetween: 100,
        },
        992: {
          slidesPerView: 4, // On md (768px and up), show 2 slides per view
          spaceBetween: 100,
        },
        1180: {
          slidesPerView: 5,
          spaceBetween: 100,
        },
      };
      // eslint-disable-next-line no-new
      const swiperThumbs = new Swiper('.sponsor-swiper-thumbs', this.data.swiperThumbOptions);
      // eslint-disable-next-line no-new
      new Swiper('.sponsor-swiper', {
        effect: 'slide',
        loop: true,
        allowTouchMove: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        thumbs: {
          // eslint-disable-next-line no-use-before-define
          swiper: swiperThumbs,
        },
      });
    }

    if (this.dom.formSubscriber) {
      this.components.subscriberFormValidation = new FormValidation(this.dom.formSubscriber);
    }
  }
}

if (document.getElementById('page-home')) {
  // eslint-disable-next-line no-new
  new Home();
}
